import slick from "slick-carousel";
import fancybox from "@fancyapps/fancybox";

$(function(){ 
    /* slider */
    $("#slider").slick({
        autoplay: true,
        autoplaySpeed: 8000,
        pauseOnFocus: false,
        pauseOnHover: false,
        speed: 1500,
        prevArrow: '<div class="prev"><i class="material-icons">navigate_before</i></div>',
        nextArrow: '<div class="next"><i class="material-icons">navigate_next</i></div>',
        dots: true,
        centerMode: true,
        fade: true,
    });
    
    /* FANCYBOX */
     $("a.fancybox").fancybox({
         loop : true,
         keyboard : true,
     });
});
